<template>
    
      <!-- Main content -->
      <section class="content">
        <div class="btn-box">
            <el-form :inline="true">
                <el-form-item label="user_id：">
                    <el-input v-model="uid" placeholder="请输入user_id"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="seach(1)">搜索</el-button></el-button> 
                </el-form-item>
            </el-form>
        </div>
        <el-table
            v-loading="loading"
            :data="mtMemberList"
            border>
            <el-table-column
                fixed
                prop="id"
                label="编号"
                width="100"
            >
            </el-table-column>
            <el-table-column
                fixed
                label="用户user_id(user_key)"
                width="200"
            >
            <template slot-scope="scope">
                {{scope.row.user_id}}({{scope.row.user_key}})
            </template>
</el-table-column>
<el-table-column prop="mobile" label="美团手机号" width="200"></el-table-column>
<el-table-column prop="amount" label="付款金额" width="100"></el-table-column>
<el-table-column label="付款时间">
    <template slot-scope="scope">
        {{scope.row.pay_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="支付状态">
    <template slot-scope="scope">
        {{scope.row.pay_status | pay_status}}
    </template>
</el-table-column>
<el-table-column label="充值时间">
    <template slot-scope="scope">
        {{scope.row.recharge_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="充值状态">
    <template slot-scope="scope">
        {{scope.row.status | status}}
    </template>
</el-table-column>
<el-table-column label="操作">
    <template slot-scope="scope">
        <el-button type="primary" size="mini" @click="alter(scope.row.id)" v-if="scope.row.status === 4">重新充值</el-button>
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

</section>
<!-- /.content -->
</template>

<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    import * as api from '@/config/api'
    export default {
        name: 'mtMember',
        components: {},

        data() {
            return {
                mtMemberList: [],
                total: 0,
                page: 1,
                pagesize: 10,
                uid: '',
                orderId: '',
                loading: true
            }
        },
        watch: {},
        filters: {
            status(val) {
                let name = ''
                if (JSON.stringify(common.business_order_status_list) != undefined && JSON.stringify(common.business_order_status_list).indexOf(val) != -1) {
                    common.business_order_status_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            pay_status(val) {
                let name = ''
                if (JSON.stringify(common.pay_status_list).indexOf(val) != -1) {
                    common.pay_status_list.map((a, index) => {
                        if (val === a.type) {
                            name = a.name
                        }
                    })
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getMtMemberList()
        },
        methods: {
            close() {
                this.show = false
                this.getMtMemberList()
            },
            getMtMemberList(page, size) {
                this.loading = true
                api.getMtMemberList({
                    user_id: this.uid,
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.mtMemberList = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getMtMemberList()
            },
            currentChange(val) {
                this.page = val
                this.getMtMemberList()
            },
            seach(page, size) {
                this.getMtMemberList(page, size)
            },
            alter(id) {
                this.$confirm('请确定是否重新充值', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.rechargeMtmember({
                        id: id
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: res.msg
                        })
                    })
                })
            }
        }
    }
</script>
<style scoped>
    .cell {
        text-align: center;
    }
    
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    img {
        width: 150px;
    }
</style>